<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width picto-dowload">
      <div class="picto-block">
        <div class="picto-block__img" v-for="(img, i) in images" :key="i">
          <img :src="require(`../../../assets/img/picto-page/${img}`)" alt="">
        </div>
      </div>
      <!--      <PictoSlider v-bind:slides="slides"/>-->
      <ButtonDownload
        text-button="Скачать пиктограммы"
        url="04-Icons/Steor-Icons.zip"
        class="page__download-block"
        :icons="[{url: '', icon: 'ai.svg'}, {url: '', icon: 'png.svg'}]"
      />
    </div>

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import BottomNavBlock from "../../../components/Elements/BottomNavBlock/BottomNavBlock"

export default {
  components: {BottomNavBlock, ButtonDownload},
  data() {
    return {
      bottomNav: {
        pre: {
          url: "/identity/typography",
          text: "Типографика"
        },
        next: {
          url: "/identity/patterns",
          text: "Паттерны"
        }
      },
      images: [
        "Steor-Icon-01.svg",
        "Steor-Icon-02.svg",
        "Steor-Icon-03.svg",
        "Steor-Icon-04.svg",
        "Steor-Icon-05.svg",
        "Steor-Icon-06.svg",
        "Steor-Icon-07.svg",
        "Steor-Icon-08.svg",
        "Steor-Icon-09.svg",
        "Steor-Icon-10.svg",
        "Steor-Icon-11.svg"
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/text-style";
@import "src/assets/style/page-block";
@import "src/assets/style/buttons";

.page {
  margin-bottom: 192px;

  &__full-width {
    max-width: 832px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.picto-dowload {
  .picto-block {
    display: flex;
    flex-wrap: wrap;

    .picto-block__img {
      width: 256px;
      height: 256px;
      margin-right: 32px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(n + 4) {
        margin-top: 32px;
      }

      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }
  }

}

.page__download-block {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.03em;
  margin-top: 96px;
  margin-left: 0;
  color: #FFFFFF;
  svg {
    margin-right: 8px;
  }
}

/deep/.main-btn_download {
  padding-right: 30px;
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 204px;
  }
}

@media screen and (max-width: 1246px) {
  .page__download-block {
    display: none;
  }
}
@media screen and (max-width: 1087px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          .picto-block {
            .picto-block__img {
              &:nth-child(3n) {
                margin-top: 32px;
                margin-right: 32px;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 610px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          .picto-block {
            .picto-block__img {
              width: 104px;
              height: 104px;
              margin-top: 16px;
              &:nth-child(3n) {
                margin-top: 16px;
              }
              &:nth-child(n + 4) {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .pages-wrap {
    .container {
      .page {
        margin-bottom: 192px;
        .page__full-width {
          .picto-block {
            min-width: 344px;
            .picto-block__img {
              width: 104px;
              height: 104px;
              margin: 0 16px 0 0;

              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 439px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          .picto-block {
            .picto-block__img {
              margin-right: 16px;
              &:nth-child(3n) {
                margin-right: 0;
              }
              &:not(:nth-child(n + 4)) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
